import React, { Component } from 'react'
import './AudioRcorderComponent.css'
import S3 from 'react-aws-s3';

class AudioRcorderComponent extends Component {


    constructor(props) {
        super(props)

        this.state = {
            mediaRecorder: null,
            recordState: null,
            src: null,
            playing: 0,
            audioData: null,
            time: 0,
            uploading: 0,
            audio: null
        }
    }


    start = () => {
        try {
            let audioIN = { audio: true };
            //  audio is true, for recording
    
            // Access the permission for use
            // the microphone
            navigator.mediaDevices.getUserMedia(audioIN)
                .then((mediaStreamObj) => {
    
                    this.setState({
                        recordState: "start",
                        time: 0,
                        duration: '00:00'
                    })
    
                    this.interval = setInterval(() => {
                        let seconds = this.state.time + 1
                        let minutes = Math.floor(seconds / 60);
                        let sec = seconds - minutes * 60;
                        if (minutes < 10) {
                            minutes = '00'
                        }
                        if (sec < 10) {
                            sec = "0" + sec
                        }
    
                        this.setState({ time: seconds, duration: `${minutes}:${sec}` })
                    }, 1000);
    
                    let mediaRecorder = null
                    if (this.state.mediaRecorder) {
                        mediaRecorder = this.state.mediaRecorder
                    } else {
                        mediaRecorder = new MediaRecorder(mediaStreamObj)
                        this.setState({ mediaRecorder })
                    }
    
                    mediaRecorder.start();
    
    
    
                    mediaRecorder.ondataavailable = function (ev) {
                        dataArray.push(ev.data);
                    }
    
    
                    let dataArray = [];
    
                    // Convert the audio data in to blob 
                    // after stopping the recording
                    mediaRecorder.onstop = (ev) => {
    
                        // blob of type mp3
                        let audioData = new Blob(dataArray,
                            { 'type': 'audio/mp3' });
    
    
                        this.onStop(audioData)
    
                        // After fill up the chunk 
                        // array make it empty
                        dataArray = [];
    
                        // Creating audio url with reference 
                        // of created blob named 'audioData'
                        let audioSrc = window.URL
                            .createObjectURL(audioData);
    
                        let audio = new Audio(audioSrc);
    
                        this.setState({ audio })
                    }
                })
    
                // If any error occurs then handles the error 
                .catch(function (err) {
                    const metaData = {}
                    metaData[err.name] = err.message
                    window.analytics.track("ERROR_DURING_AUDIO_RECORD", metaData);
                });
        }
        catch(err) {
            const metaData = {}
            metaData[err.name] = err.message
            window.analytics.track("ERROR_INITIALISING_AUDIO", metaData);
        }

    }

    stop = () => {
        this.setState({
            recordState: "stop"
        })
        const mediaRecorder = this.state.mediaRecorder
        mediaRecorder.stop()

    }

    //audioData contains blob and blobUrl
    onStop = (audioData) => {
        this.setState({ src: audioData.url, audioData: audioData, duration: '00:00' })
        clearInterval(this.interval);
    }

    play = () => {
        let mAudio = this.state.audio
        if (mAudio) {
            mAudio.onended = () => {
                this.setState({ playing: 0 })
            };
            if (mAudio.paused) {
                mAudio.play();
                this.setState({ playing: 1 })
            } else {
                this.setState({ playing: 0 })
                mAudio.pause();
            }
        }
    }

    delete = () => {
        let mAudio = this.state.audio
        if (mAudio) {
            mAudio.pause();
            mAudio.currentTime = 0;
        }
        this.setState({ recordState: null, audio: null })
    }

    upload = () => {
        this.setState({ uploading: 1 })

        const date = new Date();
        const fileName = "bot_audio_" + date.getTime()

        const config = {
            bucketName: "jobboxapi",
            dirName: "ChatbotAudio",
            region: "ap-south-1",
            accessKeyId: "AKIASYNBOOOJRYEZ6OVR",
            secretAccessKey: "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",
            contentType: "audio/mp3"
        }

        const ReactS3Client = new S3(config);


        const audioFile = new File([this.state.audioData], fileName + ".mp3")
        ReactS3Client.uploadFile(audioFile, fileName + ".mp3").then(data => {
            this.setState({
                recordState: null,
                src: null,
                playing: 0,
                audioData: null,
                time: 0,
                uploading: 0
            })
            this.setState({ audio: null })
            this.props.audioUploaded(data.location)
        });
    }

    render() {

        return (
            <div>
                <div style={{ display: 'none' }}>
                    {/* <AudioReactRecorder state={recordState} onStop={this.onStop} /> */}
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className={this.state.uploading === 0 ? "" : "hide"}>
                        <div className={this.state.recordState === "stop" && this.state.playing === 0 ? "play_btn" : "hide"} onClick={this.play}>
                            <i className="fa fa-play" aria-hidden="true"></i>
                            <br />
                            <p className="rec_duration">Play</p>
                        </div>
                        <div className={this.state.recordState === "stop" && this.state.playing === 1 ? "pause_btn" : "hide"} onClick={this.play}>
                            <i className="fa fa-pause" aria-hidden="true"></i>
                            <br />
                            <p className="rec_duration">Pause</p>
                        </div>
                        <div className={this.state.recordState === "stop" ? "audio_del_btn" : "hide"} onClick={this.delete}>
                            <i className="fa fa-trash" aria-hidden="true"></i><br />
                            <p className="rec_duration">delete</p>

                        </div>
                        <div className={this.state.recordState === "stop" ? "send_btn" : "hide"} onClick={this.upload}>
                            <i className="fa fa-paper-plane" aria-hidden="true"></i><br />
                            <p className="rec_duration">Send</p>
                        </div>
                        <div className={this.state.recordState === null ? "up_button" : "hide"} onClick={this.start}>
                            <i className="fa fa-microphone" style={{ fontSize: '24px' }} aria-hidden="true"></i><br />
                            <div className="record_text">Record</div>
                        </div>
                        <div className={this.state.recordState === "start" ? "down_button" : "hide"} onClick={this.stop}>
                            <i className="fa fa-stop" style={{ fontSize: '24px' }} aria-hidden="true" ></i><br />
                            <p className="rec_duration">{this.state.duration}</p>
                        </div>
                    </div>
                    <div className={this.state.uploading === 0 ? "hide" : ""}>

                        <div className={this.state.uploading === 1 ? "fa-2x" : "hide"}>
                            <i className="fas fa-spinner fa-pulse"></i>
                            Uploading<br/><br/>
                        </div>
                        <div className={this.state.uploading === 2 ? "success_text" : "hide"}>
                            Success
                            <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default AudioRcorderComponent;