import React, { Component } from 'react';
import './../ChatInput.css'

class ChatPanelComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "selected_options": "",
      "flag": 0,
      "next": 0,
      "eval_type": "and",
      "field": "",
      "audioplayed":false
    }
  }

  handleChange = (li, next, flag, field) => {
    this.props.sendReply(li, next, flag, field)
  }

  handleAudioNext = (li, next, flag, field) => {
    if(this.state.audioplayed) {
      this.handleChange(li, next, flag, field)
    } else {
      this.props.showToast()
    }
  }

  handleSelect = (e, next, evalType, flag, field) => {
    var temp = this.state.selected_options
    var tempFlag = this.state.flag
    if (temp.includes(e + ',')) {
      temp = temp.replace(e + ',', '')
      if (evalType === "or") {
        tempFlag -= parseInt(flag)
      } else {
        tempFlag -= parseInt(flag) - 1
      }

    } else {
      if (evalType === "or") {
        tempFlag += parseInt(flag)
      } else {
        tempFlag += parseInt(flag) - 1
      }
      temp += e + ','
    }
    this.setState({ selected_options: temp, flag: tempFlag, next: next, eval_type: evalType, field: field })
  }

  sendList = () => {
    if (this.state.selected_options !== "") {
      var flag = 0
      if ((this.state.eval_type === "or" && this.state.flag > 0) || (this.state.eval_type === "and" && this.state.flag >= 0)) {
        flag = 1
      }
      this.props.sendReply(this.state.selected_options, this.state.next, flag, this.state.field)
      this.setState({ selected_options: "", flag: 0, next: 0 })
    }
  }

  setField = (field) => {
    // this.props.setField(field)
  }

  notify = () => {
    let redirect_link =  'https://ablejobs.page.link/?link=http://ablejobs.co/invite?'+this.props.refer+'&apn=com.get.jobbox'
    window.open(redirect_link);

  }
  
  onPlay = () => {
    this.setState({audioplayed:true})
  }

  render() {

    let style = { top: '60px', bottom: '60px', position: 'absolute', overflow: 'auto', width: '100%' }
    if (this.props.field === "record_audio" || this.props.field === "record_audio2") {
      style.bottom = '100px'
    }

    return (
      <div id="chatMessages" className="" style={style}>
        {console.log("##########Message Map##########")}
        {console.log(this.props.messageList)}
        {this.props.messageList.map(function (message, i) {
          if (message.type === 0) {
            if (message["field"] === "custom") {
              // return (
              //   <div id={message.commentId} key={i} className={message["user"] === 0 ? "user0" : "user1"}>
              //     <div className="">
              //       <div className={message["user"] === 0 ? "usertext0" : "usertext1"} dangerouslySetInnerHTML={{ __html: message["description"] }} />
              //       <div onClick={() => { this.notify() }} style={{ background: '#c4c4c4', padding: '10px', marginBottom: '10px', borderRadius: '5px', textAlign: 'center' }} >Notify me!</div>
              //       <span className="time">
              //         {message["time"]}
              //       </span>
              //     </div>
              //   </div>)

              return (
                <div id={message.commentId} key={i} className={message["user"] === 0 ? "user0" : "user1"}>
                  <div className="">
                    <div className={message["user"] === 0 ? "usertext0" : "usertext1"} dangerouslySetInnerHTML={{ __html: message["description"] }} />
                    <div onClick={() => { this.notify() }} style={{ background: '#c4c4c4', padding: '10px', marginBottom: '10px', borderRadius: '5px', textAlign: 'center' }} >Download Now</div>
                    <span className="time">
                      {message["time"]}
                    </span>
                  </div>
                </div>)
            }
            return (
              <div id={message.commentId} key={i} className={message["user"] === 0 ? "user0" : "user1"}>
                <div className="">
                  <div className={message["user"] === 0 ? "usertext0" : "usertext1"} dangerouslySetInnerHTML={{ __html: message["description"] }} />
                  <span className="time">
                    {message["time"]}
                  </span>
                </div>
              </div>)
          } else if (message.type === 1) {
            return (
              <div id={message.commentId} key={i} className={message["user"] === 0 ? "user0" : "user1"}>
                <div className="">
                  <div style={{ marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }} dangerouslySetInnerHTML={{ __html: message["description"] }}></div>
                  {message["list"].map((li, i) => {
                    return (
                      <div key={i} onClick={() => { this.handleChange(li, message["next"][i], message["flag"][i], message["field"]) }} style={{ background: '#c4c4c4', padding: '10px', marginBottom: '10px', borderRadius: '5px', cursor: 'pointer' }}>{li}</div>
                    )
                  })}
                  <span className="time">
                    {message["time"]}
                  </span>
                </div>
              </div>
            )
          }
          else if (message.type === 2) {
            return (
              <div id={message.commentId} key={i} className={message["user"] === 0 ? "user0" : "user1"}>
                <div className="">
                  <div style={{ marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }} dangerouslySetInnerHTML={{ __html: message["description"] }}></div>
                  {message["list"].map((li, i) => {
                    return (
                      <div key={i} className="loading" onClick={() => { this.handleChange(li, message["next"][i], message["flag"][i], message["field"]) }} style={{ background: '#c4c4c4', marginBottom: '10px', cursor: 'pointer' }}><span className="text">{li}</span><span className="border"></span></div>
                    )
                  })}
                  <span className="time">
                    {message["time"]}
                  </span>
                </div>
              </div>
            )
          }
          else if (message.type === 3) {
            return (
              <div id={message.commentId} key={i} className={message["user"] === 0 ? "user0" : "user1"}>
                <div className="">
                  <div style={{ marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }} dangerouslySetInnerHTML={{ __html: message["description"] }}></div>
                  {message["list"].map((li, j) => {
                    return (
                      <div key={i + li}>
                        <div style={{ padding: '2px', paddingLeft: '10px', background: '#f2f2f2', margin: '5px', paddingRight: '10px' }}>
                          <label htmlFor={i + "-" + j} className="container"><p style={{ fontSize: '15px', minHeight: '25px' }}>{li}</p>
                            <input className="checkbox" onChange={() => { this.handleSelect(li, message["next"], message["eval_type"], message["flag"][j], message["field"]) }} id={i + "-" + j} type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </div >
                        {message["list"].length === j + 1 && <div onClick={() => { this.sendList() }} style={{ background: '#c4c4c4', padding: '10px', marginBottom: '10px', borderRadius: '5px', textAlign: 'center' }} >Okay</div>}
                      </div>
                    )
                  })}
                  <span className="time">
                    {message["time"]}
                  </span>
                </div>
              </div>
            )
          }
          else if (message.type === 4) {
            return (
              <div id={message.commentId} key={i} className="audio">
                <div className="">
                  <div style={{ marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }} dangerouslySetInnerHTML={{ __html: message["description"] }}></div>
                  <br/>
                  <audio onPlay={this.onPlay} controls>
                    <source src={message.audio} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio><br/><br/>
                  {message["list"].map((li, i) => {
                    return (
                      <div key={i} onClick={() => { this.handleAudioNext(li, message["next"][i], message["flag"][i], message["field"]) }} style={{ background: '#c4c4c4', padding: '10px', marginBottom: '10px', borderRadius: '5px', cursor: 'pointer' }}>{li}</div>
                    )
                  })}
                  <span className="time">
                    {message["time"]}
                  </span>
                </div>
              </div>
            )
          }

        }.bind(this))}
      </div>
    )
  }
}


export default ChatPanelComponent;
