import React, { Component } from 'react';
import './ChatInput.css'

class MessageInputComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message : ""
    }
  }

  sendReply= (e) => {
    if (e.key === "Enter") {
      this.sendMessage();
    }
  }

  sendMessage = () => {
    if  (this.state.message !== "") {
      this.props.sendMessage(this.state.message)
      this.setState({message:""})
    }
  }

  setMessage = (e) => {
    this.setState({message:e.target.value})
  }

  render() {
    
    return (
      <div className="" style={{position:'absolute', height:'60px', overflow:'hidden', bottom:'0', width:'100%'}}>


        <div className="" style={{display:'table', clear:'both', marginTop:'5px', width:'100%'}}>
          <div className="" style={{width:'calc(100% - 70px)', marginLeft:'10px', float:'left'}}>
            <input
              style={{height:'45px', background:'white', width:'100%', borderRadius:'24px', border:'none', paddingLeft:'10px', outline:'0px'}}
              className=""
              disabled={this.props.disabled}
              type={this.props.input}
              name="inputChatMessage"
              value = {this.state.message}
              onKeyPress={this.sendReply}
              onChange={this.setMessage}
              placeholder={this.props.disabled?"Select an option":"Type a message"} />
          </div>
          <div className="text-center" style={{width:"50px", float:'left', marginLeft:'10px', marginTop:'2px'}}>
          
          <div onClick={this.sendMessage} style={{width:'45px', borderRadius:'50%', background:'#075E54', height:'45px', color:'white', fontSize:'24px', padding:'5px 3px 0 0'}}>
            <i className="fa fa-paper-plane" aria-hidden="true"></i>
          </div>
            
          </div>
        </div>


      </div>
    )
  }
}


export default MessageInputComponent;
