import React, { Component } from 'react';
import axios from 'axios';
import { config } from '../../Constants'
const $ = window.$;

class SubscribeModalComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'error': false,
            'email': '',
            'loading': false
        }
    }

    validateEmail = () => {
        if (this.state.email === "") {
            this.setState({ error: true });
            return false;
        } else {
            this.setState({ error: false });
            return true;
        }
    }

    handleInput = (e) => {
        this.setState({ email: e.target.value })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validateEmail()) {


            // send email to chatMessageComponent
            if (this.props.addEmailToFirebase) {
                this.props.addEmailToFirebase(this.state.email)
            }

            window.analytics.identify({
                "email":this.state.email
            });
            alert('Registration Successful');
            $('#myModal').modal('hide');
        }
    }

    render() {
        return (    
            <div id="myModal" className="modal fade" role="dialog">
                <div className="modal-dialog  modal-sm">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <br />
                            <h5>Subscribe to get notified about latest job openings.</h5>
                            {this.state.error && <p style={{ color: 'red' }}>Enter your email!</p>}
                            <form onSubmit={this.handleSubmit}>
                                <input id="email" type="email" onChange={this.handleInput} className="form-control" name="email" placeholder="Enter your email address"></input>
                                {this.state.loading && <div>Wait we are subscribing you..
                                    <i className="fa fa-circle-o-notch fa-spin" style={{ fontSize: "24px" }}></i>
                                </div>}
                                <br />
                                <button type="button" type="submit" className="btn btn-primary center-block">Subscribe Now</button>
                                <br />
                                <a data-dismiss="modal" style={{ textAlign: 'center' }} className="center-block" >No Thanks</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SubscribeModalComponent;