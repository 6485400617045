import Rebase from 're-base';
import firebase from 'firebase';
import  { env_config } from './Constants';

const config = {
    apiKey: env_config.firebase.APIKEY,
    authDomain: env_config.firebase.AUTH_DOMAIN,
    databaseURL: env_config.firebase.DATABASE_URL,
    projectId: env_config.firebase.PROJECT_ID,
    storageBucket: env_config.firebase.STORAGE_BUCKET,
    messagingSenderId: env_config.firebase.MESSAGING_SENDER_ID,
    appId: env_config.firebase.APPID
};

const app = firebase.initializeApp(config)
const base = Rebase.createClass(app.database())
const rootRef = firebase.database().ref().child("candidates")
var messaging = null

if(firebase.messaging.isSupported()) {
  messaging = app.messaging();
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    env_config.firebase.CLOUD_MESSAGE_KEY
  );
}

export { app,rootRef,messaging, base}
