import React, { Component } from 'react';
import MessageInputComponent from './MessageInputComponent.js'
import ChatPanelComponent from './ChatPannelComponent/ChatPanelComponent.js'
import { messaging, base } from '../../base'
import './ChatInput.css';
import axios from 'axios';
import AudioRcorderComponent from '../AudioRecorderComponent/AudioRecorderComponent'
import SubscribeModalComponent from '../SubscribeModalComponent/SubscribeModalComponent';
import { config } from '../../Constants.js'
import background from '../../assets/background.png'
import HeaderContainer from '../HeaderComponent/HeaderComponent';
const $ = window.$;

class ChatMsgComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      "inputChatMessage": "",
      "state": "1",
      "disable_typing": false,
      flag: 0,
      "ref_flag":0,
      "field": "",
      "name": "",
      "mobile": "",
      "location":"",
      "salary":"",
      "experience":"",
      "airtablename": "",
      "refer":this.props.refer,
      "open": false,
      "input_type": "text",
      "one_on_one_messages": { chatMessages: [] },
      "newchat": [],
      "userChatData": {
        "id":this.props.id,
        "opening_id": this.props.bot_tracking_data.opening_id,
        "company_opening": this.props.bot_tracking_data.company_name,
        "course": this.props.bot_tracking_data.course,
        "utm_campaign": this.props.bot_tracking_data.utm_campaign,
        "utm_medium": this.props.bot_tracking_data.utm_medium,
        "utm_content": this.props.bot_tracking_data.utm_content,
        "utm_term": this.props.bot_tracking_data.utm_term,
        "utm_source": this.props.bot_tracking_data.utm_source
      }
    }
  }


  //sent by the user by selecting an option
  sendOptionReply = (ans, next, flag, field) => {
    var newMessage = {
      description: ans,
      type: 0,
      time: this.getCurrentTime(),
      user: 0
    }
    if (flag === 0) {
      flag = this.state.flag
    }
    var copy = this.state.one_on_one_messages;
    copy["chatMessages"].push(newMessage)
    var stateField = ""
    if (field && field !== "") {
      copy[field] = ans
      stateField = field
    }
    this.setState({ one_on_one_messages: copy, disable_typing: true, input_type: "text", flag: flag, field: stateField }, () => {
      this.scrollToBottom()
      this.getResponseMessage(next)
      this.saveDateToFirebase(ans)
    })
  }

  addnewMessage(newMessage, typing, input_type, field, flag) {
    var copy = this.state.one_on_one_messages;
    copy["chatMessages"].push(newMessage)
    this.setState({ one_on_one_messages: copy, disable_typing: typing, input_type: input_type, field: field, flag: flag }, () => {
      this.scrollToBottom()
    })
  }

  getResponseMessage(index) {
    var data = this.state.newchat[index]

    var text = data.description
    
    var type = data.type
    var audio = ""
    if(data.audio) {
      audio = data.audio
    }
    let field = ""
    if (data.field) {
      field = data.field
    }

    var input_type = ""
    var next = []
    var list = []
    var flag = []
    var evalType = "and"
    if (data.eval_type) {
      evalType = data.eval_type
    }
    var typing = true
    if (type === 0 || type === "last") {//type last is for ending the chat and set disable-typing to true
      console.log("#########text###############");
      console.log(data);
      if (this.state.flag === 0 || !data.next_f) {
        next = data.next_p?data.next_p:0
      } else {
        next = data.next_f
      }
      if (type === 0) {
        typing = false
      } else {
        type = 0
        //alert(this.state.mobile)
        // if (this.state.flag === 0 && this.props.bot_tracking_data.jot_form_message === "1") {
        //   let url = 'https://clients.ablejobs.co/recruitcrm/send_proactive_whatsappmessage'
        //   let message_data = {
        //       "mobile": [this.state.mobile],
        //       "template_name": "track_applicant_assessment",
        //       "template_data": [
        //           this.state.name,
        //           this.props.bot_tracking_data.company_name,
        //           `https://form.jotform.com/211032838285454?name=${this.state.name}&phone=${this.state.mobile}&utm_campaign=${this.props.bot_tracking_data.utm_campaign}&utm_medium=${this.props.bot_tracking_data.utm_medium}&utm_source=${this.props.bot_tracking_data.utm_source}`
        //       ]
        //   }
        //   axios.post(url, message_data).then(response => {
        //     console.log(response)
        //   }).catch(error=>{
        //     console.log(error.message)
        //   })
        // } else if (this.state.flag === 0 && this.props.bot_tracking_data.can_sourse_directly === '1') {
        //   let url = 'https://clients.ablejobs.co/recruitcrm/insert_von_lead'
        //   let message_data = {
        //       "opening_id": this.props.bot_tracking_data.opening_id,
        //       "name": this.state.name,
        //       "mobile": this.state.mobile,
        //       "company_opening": this.props.bot_tracking_data.company_name,
        //       "course": this.props.bot_tracking_data.course
        //   }
        //   axios.post(url, message_data).then(response => {
        //     console.log(response)
        //   }).catch(error=>{
        //     console.log(error.message)
        //   })
        //}
        // window.analytics.track('Result', { 'Flag': this.state.flag, 'Bot': this.state.airtablename });
        // if (this.state.flag === 0) {
          
        //   var job_role = this.props.bot_tracking_data.job_role
        //   var company_name = this.props.bot_tracking_data.company_name
        //   var job_role_city = this.props.bot_tracking_data.job_role_city
        //   var pre = "qf_"
        //   var suff = "_lead"
        //   if (job_role) {
        //     var event = pre + job_role + suff
        //     window.analytics.track(event);
        //     if (job_role_city) {
        //       event = pre + job_role + "_" +job_role_city + suff
        //       window.analytics.track(event);
        //     }
        //   }
        //   if (company_name) {
        //     var event = pre + company_name + suff
        //     window.analytics.track(event);
        //   }
        // }
      }
      input_type = data.input_type
      this.setState({ next_index: next })
    } else if (type === 3) {
      if (this.state.flag === 0 || !data.next_f) {
        next = (data.next_p)
      } else {
        next = (data.next_f)
      }
      data.options.map((op) => {
        list.push(op.text)
        if (op.flag) {
          flag.push(op.flag)
        } else {
          flag.push(0)
        }
      })
    } else {
      data.options.map((op) => {
        if (this.state.flag === 0 || !op.next_f) {
          next.push(op.next_p)
        } else {
          next.push(op.next_f)
        }
        list.push(op.text)
        if (op.flag) {
          flag.push(op.flag)
        } else {
          flag.push(0)
        }
      })
    }

    text.map((msg, i) => {

      var botReply = {
        description: msg,
        type: 0,
        time: this.getCurrentTime(),
        user: 1
      }

      if (i === text.length - 1) {
        botReply["type"] = type
        botReply["list"] = list
        botReply["next"] = next
        botReply["audio"] = audio
        botReply["flag"] = flag
        botReply["field"] = field
        botReply["eval_type"] = evalType
      }
      this.addnewMessage(botReply, typing, input_type, field, this.state.flag)
    })
    
    this.props.updateFirebase(this.state.one_on_one_messages)
  }

  getCurrentTime() {
    var date = new Date();
    var hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var last = date.getHours() > 12 ? " PM" : " AM"
    return hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last
  }

  sendTextReply = (ans) => {
    var newMessage = {
      description: ans,
      type: 0,
      time: this.getCurrentTime(),
      user: 0
    }

    this.saveDateToFirebase(ans)
    this.addnewMessage(newMessage, true, "", this.state.field, this.state.flag)
    if (this.state.field !== "phone" || this.validateMobile(ans)) {
      this.getResponseMessage(this.state.next_index)
      this.props.updateFirebase(this.state.one_on_one_messages)
    } else {
      this.showIncorrectPhoneMessage()
    }
  }

  saveDateToFirebase(ans) {
    var copy = {...this.state.one_on_one_messages}
    if (this.state.field && this.state.field !== "") {
      copy[this.state.field] = ans
      console.log("#####ANSWER#####"+this.state.field)
      console.log(this.state)
      if (this.state.field === "phone") {
        console.log("#####ANSWER#####"+this.state.field+"####Entered")
        this.setState({ mobile: ans });
        this.sendDataToPostgres(ans)
        console.log(this.state)
        //window.analytics.identify(this.state.mobile, { 'name': this.state.name, 'phone': ans });
      }
      if (this.state.field === "name") {
        this.setState({ name: ans });
      }
      if (this.state.field === "location") {
        this.setState({ location: ans });
      }
      if (this.state.field === "salary") {
        this.setState({ salary: ans });
      }
      if (this.state.field === "experience") {
        this.setState({ experience: ans });
        console.log("#########Expereince#########")
      }
      if (this.state.mobile !== "") {
        console.log("#########Hitting#############")
        this.sendDataToPostgres()

        if(this.state.ref_flag==0){
          if(this.state.refer_mobile=="+91"+this.state.mobile){
            $('#referModal').modal('show');
          }
        }
      }
      //window.analytics.track(this.state.field, { 'value': ans });
    }
    copy["flag"] = this.state.flag
    const chatDataCopy = this.state.userChatData
    if (this.state.field === "record_audio") {
      chatDataCopy["audio_link"] = ans
    } else if(this.state.field === "record_audio2") {
      chatDataCopy["audio_link2"] = ans
    } else {
      chatDataCopy[this.state.field] = ans
    }
    this.setState({ 
      one_on_one_messages: copy,
      userChatData: chatDataCopy
    })
    this.props.updateFirebase(copy)
  }

  addEmailToFirebase = (email) => {
    var copy = this.state.one_on_one_messages
    copy["email"] = email
    this.setState({ one_on_one_messages: copy })
    this.props.updateFirebase(copy)
  }

  showIncorrectPhoneMessage() {
    var msg = {
      description: "Please enter a valid 10 digit phone no!<br>Ex - 8788878788",
      type: 0,
      field: this.state.field,
      next: this.state.next_index,
      time: this.getCurrentTime(),
      user: 1
    }
    this.addnewMessage(msg, false, "number", this.state.field, this.state.flag)
    this.props.updateFirebase(this.state.one_on_one_messages)
  }

  validateMobile(mobile) {
    if (/^[6-9]\d{9}$/.test(mobile)) {
      return true
    } else {
      return false
    }
  }

  componentDidMount() {
    let copy = null
    let isOldUser = 'chatMessages' in this.props.chatData && this.props.chatData["chatMessages"].length > 0;
    let lastField = ""
    let next_index = ""
    let flag = 0
    if (isOldUser) {
      copy = this.props.chatData
      const lastMessageIndex = copy["chatMessages"].length -1
      const lastChatMessage = copy["chatMessages"][lastMessageIndex]
      if(lastChatMessage.user === 1) {
        lastField = lastChatMessage.field
        next_index = lastChatMessage.next
        flag = copy.flag? copy.flag : 0
      } else {
        isOldUser = false
        copy = this.state.one_on_one_messages
      }

    } else {
      copy = this.state.one_on_one_messages
    }
    copy["airtable"] = this.props.__chatMessages.airtablename
    copy["uid"] = this.props.id
    var utm_campaign = this.props.bot_tracking_data.utm_campaign
    var utm_medium = this.props.bot_tracking_data.utm_medium
    var utm_content = this.props.bot_tracking_data.utm_content
    var utm_term = this.props.bot_tracking_data.utm_term
    var utm_source = this.props.bot_tracking_data.utm_source
    if (utm_campaign) {
      copy["utm_campaign"] = utm_campaign
    }
    if (utm_medium) {
      copy["utm_medium"] = utm_medium
    }
    if (utm_content) {
      copy["utm_content"] = utm_content
    }
    if (utm_term) {
      copy["utm_term"] = utm_term
    }
    if (utm_source) {
      copy["utm_source"] = utm_source
    }
    this.setState({ airtablename: this.props.__chatMessages.airtablename,
                    newchat: this.props.__chatMessages.chatdata,
                    field: lastField,
                    flag: flag,
                    next_index: next_index,
                    one_on_one_messages: copy }, () => {
      if (isOldUser) {
        // nothing
      } else {
        this.sendOptionReply("hi", 0, 0, "")
      }
      this.scrollToBottom()
      window.analytics.track('Begin', { 'Bot': this.state.airtablename });
    })

    this.checkmobileAlreadyExits();
  }

  showToast = () => {
    $("#toast").slideDown(1000,()=>{
      setTimeout(() => $("#toast").slideUp(1000), 1500);
    });
  }

  scrollToBottom = () => {
    var scrollToElement = document.getElementById("chatMessages");
    if(scrollToElement)
    scrollToElement.scrollTo({
      top: (scrollToElement.scrollHeight),
      behavior: 'smooth',
    });
  }

  setNotify() {
    $('#myModal').modal('show');
  }

  audioUploaded = (link) => {
      var msg = {
        description: "🎵🎵🎵",
        type: 0,
        time: this.getCurrentTime(),
        user: 0
      }
      this.addnewMessage(msg, false, "number", this.state.field, this.state.flag)
      this.saveDateToFirebase(link)
      if(this.state.field === 'record_audio2') {

        this.sendDataToPostgres()
        
      }
      this.getResponseMessage(this.state.next_index)
  }

  sendDataToPostgres(phone) {
    console.log("#323233##########");
    const url = 'https://clients.ablejobs.co/app/insert_chatbot_leads'
    let data = {
      "name": this.state.name,
      "phone": phone,
      "location":this.state.location,
      "salary":this.state.salary,
      "experience":this.state.experience,
      "airtablename": this.state.airtablename,
      "refer":this.state.refer,
      "id":this.state.userChatData.id
    }
    console.log(data)
    axios.post(url, data).then(response => {
      console.log("response",response)
    }).catch(error=>{
      console.log(error.message)
    })
  }

  checkmobileAlreadyExits(){
    if(this.state.refer){
      const url = 'https://clients.ablejobs.co/appusers/check_refer_code_mobile?refer_code='+this.state.refer
      axios.get(url).then(response => {
        console.log("########REFER##########")
        console.log("response",response)
        this.setState({ refer_mobile:response.data.mobile});
      }).catch(error=>{
        console.log(error.message)
      })
    }
    else{
      $('#noReferCodeModal').modal('show'); 
    }
  }

  sendDataToPostgres() {
    const url = 'https://clients.ablejobs.co/app/insert_chatbot_leads'
    let data = {
      "name": this.state.name,
      "phone": this.state.mobile,
      "location":this.state.location,
      "salary":this.state.salary,
      "experience":this.state.experience,
      "airtablename": this.state.airtablename,
      "refer":this.state.refer,
      "id":this.state.userChatData.id
    }

    axios.post(url, data).then(response => {
      console.log("response",response)

    }).catch(error=>{
      console.log(error.message)

    })
  }

  sendForInvite = () =>{
    window.open("whatsapp://send?text= Download Able app for *FREE Interview preparation* and apply to *Top Companies* https://jobs.ablejobs.co/wfh/"+this.state.refer)
  }

  render() {
    return (
      <div style={{position:'relative', height:'100%', width:'100%'}} >
        <img style={{height:"100%", width:'100%', position:'absolute', zIndex:'0'}} src={background}/>
        <div style={{height:"100%", width:'100%', background:'#ece5dd', position:'absolute', zIndex:'-1'}} ></div>
        
        <div style={{height:"100%", width:'100%', position:'absolute', zIndex:'1'}} >
        <HeaderContainer active={false} />
        <SubscribeModalComponent userName={this.state.one_on_one_messages.name} mobile={this.state.one_on_one_messages.phone}
          addEmailToFirebase={this.addEmailToFirebase} />

        <ChatPanelComponent
          messageList={this.state.one_on_one_messages["chatMessages"]}
          sendReply={this.sendOptionReply}
          setNotify={this.setNotify}
          showToast={this.showToast}
          field={this.state.field}
          refer={this.state.refer}
        />

        <div id="toast" className="toast" hidden>
          <p className="toast_message">Listen to the audio first!</p>
        </div>

        {this.state.field === 'record_audio' || this.state.field === 'record_audio2'?
          <div style={{position:'absolute', width:'100%', zIndex:'1000', bottom:'0'}}>
            <AudioRcorderComponent audioUploaded={this.audioUploaded}/>
          </div>
        
          :

          <MessageInputComponent
            disabled={this.state.disable_typing}
            inputChatMessage={this.state.inputChatMessage}
            sendMessage={this.sendTextReply}
            input={this.state.input_type} />
          }
        </div>
        <div id="referModal" data-backdrop="false"  className="modal fade" role="dialog">
            <div className="modal-dialog ">

              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">You are already an Able User</h4>
                  <br/>
                  Share this link with your 3 friends to get your <b>Free Resume</b> and <b>Job Alerts</b>.
                  <br/>
                  <button className="btn btn-success" onClick={this.sendForInvite}> Share on Whatsapp</button>
                </div>
              </div>

            </div>
        </div>
        <div id="noReferCodeModal" data-backdrop="false"  className="modal fade" role="dialog">
            <div className="modal-dialog ">

              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">⚠️ Please use your refer link</h4>
                  <br/>
                  You can only view this page if you are invited. Please click the <b>invite link</b> with a refer code to visit this page.
                  <img className="img-responsive"  style={{height:'200px'}} src="https://d3mma52s4lc9u0.cloudfront.net/1656590036541Screenshot_20220630-171049_WhatsApp.jpg"></img>
                  <br/>
                </div>
              </div>

            </div>
        </div>
      </div>
    )
  }
}


export default ChatMsgComponent;
