import React, { Component } from 'react';
import HeaderContainer from './HeaderContainer/HeaderContainer.js';
import ChatMsgContainer from './ChatMsgContainer/ChatMsgContainer.js';
import { base, rootRef } from '../base';
import { config } from '../Constants.js'
class CombinedComponents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      candidates: {},
      loading:true,
      testjson:{},
      id:"",
      bot_tracking_data:{}
    }
    this.sendMessage = () => {
      return this.state.testjson
    }

  }

  updateFirebase = (newValue) => {
    const candidates = newValue
    console.log("updating firebase to -> ", newValue)
    this.setState({ candidates })
  }


  componentWillMount() {

    var utm_campaign = new URLSearchParams(this.props.location.search).get("utm_campaign")
    var utm_medium = new URLSearchParams(this.props.location.search).get("utm_medium")
    var utm_content = new URLSearchParams(this.props.location.search).get("utm_content")
    var utm_term = new URLSearchParams(this.props.location.search).get("utm_term")
    var utm_source = new URLSearchParams(this.props.location.search).get("utm_source")

    var bot_tracking_data = {
      utm_campaign:utm_campaign,
      utm_medium:utm_medium,
      utm_content:utm_content,
      utm_term:utm_term,
      utm_source:utm_source
    }

    if (this.props.match.params.id){
      var botid = this.props.match.params.id;
    } else {
      var botid = "toppr"
    }
    this.testjsonRef = base.syncState("chatbots/"+botid, {
      context: this,
      state: 'testjson',
      then: (() => {
          if(this.state.testjson.job_role && this.state.testjson.job_role_city) {
            bot_tracking_data["job_role"] = this.state.testjson.job_role
            bot_tracking_data["job_role_city"] = this.state.testjson.job_role_city
          }
          bot_tracking_data["company_name"] = this.state.testjson.company_name
          bot_tracking_data["jot_form_message"] = this.state.testjson.jot_form_message
          bot_tracking_data["can_sourse_directly"] = this.state.testjson.can_sourse_directly
          bot_tracking_data["opening_id"] = this.state.testjson.opening_id
          bot_tracking_data["course"] = this.state.testjson.course

          this.setState({ bot_tracking_data: bot_tracking_data }, ()=>{
            if(this.state.testjson.chatdata) {
            }
          })
      })

    })

    var refer = this.props.match.params.slug;
    var contact = this.props.match.params.slug;
    var date = Date.now();
    contact = 'f'+date
    // if(contact===undefined){
    //   contact = 'f'+date
    // }
    this.setState({id:contact,refer:refer})
    this.candidatesRef = base.syncState('candidates/' + contact, {
      context: this,
      state: 'candidates',
      then: (() => {
        this.setState({ loading:false })
      })
    });

  }

  componentWillUnmount() {
    base.removeBinding(this.candidatesRef)
    base.removeBinding(this.testjsonRef)
  }

  componentDidMount() {
    // this.addCandidate(contact)
  }

  render() {
    return (
      <div>
        {this.state.loading ? <div><HeaderContainer active={false} />
            <div style={{ paddingLeft: '20px', top:'60px', bottom:'60px', position:'absolute', overflow:'auto', fontSize:"20px", background:'#ece5dd', width:'100%', height:"calc(100%-60px)", paddingTop:"20px", position:'absolute', bottom:'0' }}> 
            <br/>
            <br/>
            <br/>
            <div> Searching jobs near you please wait.. 
            <br/>
            <br/>
            <div className="fa-3x" style={{textAlign:'center'}}>
              <i className="fas fa-spinner fa-spin"></i>
            </div>
            </div>
            </div>
          </div>:
          <div >
            <div style={{height:'100%', position:'absolute', zIndex:'2', width:'100%'}}>
              <ChatMsgContainer
                chatData={this.state.candidates}
                bot_tracking_data={this.state.bot_tracking_data}
                id={this.state.id}
                refer={this.state.refer}
                updateFirebase={this.updateFirebase}
                _chatMessages={this.sendMessage()} />
            </div>
          </div> 
        }
      </div>
    )
  }
}


export default CombinedComponents;
