import React, {Component} from 'react';
import ChatMsgComponent from '../../Component/ChatMsgComponent/ChatMsgComponent.js';

class ChatMsgContainer extends Component {

  render() {
    return (
      <ChatMsgComponent refer={this.props.refer} chatData={this.props.chatData} bot_tracking_data={this.props.bot_tracking_data} setName={this.props.setName} updateFirebase={this.props.updateFirebase}  __chatMessages={this.props._chatMessages} 
      id = {this.props.id}/>
    )
  }
}


export default ChatMsgContainer;
