const prod = {
    firebase: {
        APIKEY: process.env.REACT_APP_APIKEY,
        AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
        DATABASE_URL: process.env.REACT_APP_DATABASE_URL,
        PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
        STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET,
        MESSAGING_SENDER_ID: process.env.REACT_APP_MESSAGING_SENDER_ID,
        APPID: process.env.REACT_APP_APPID,
        CLOUD_MESSAGE_KEY: process.env.REACT_APP_CLOUD_MESSAGE_KEY 
    }
};

const dev = {
    firebase: {
        APIKEY: process.env.REACT_APP_APIKEY,
        AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
        DATABASE_URL: process.env.REACT_APP_DATABASE_URL,
        PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
        STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET,
        MESSAGING_SENDER_ID: process.env.REACT_APP_MESSAGING_SENDER_ID,
        APPID: process.env.REACT_APP_APPID,
        CLOUD_MESSAGE_KEY: process.env.REACT_APP_CLOUD_MESSAGE_KEY
    }
};


export const env_config = process.env.NODE_ENV === 'development' ? dev : prod;
